import $ from 'jquery';
window.jQuery = $;
window.$ = $;

jQuery.event.special.touchstart = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.touchmove = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.wheel = {
  setup: function( _, ns, handle ){
      this.addEventListener("wheel", handle, { passive: true });
  }
};
jQuery.event.special.mousewheel = {
  setup: function( _, ns, handle ){
      this.addEventListener("mousewheel", handle, { passive: true });
  }
};
// import './js/navigation.js'; 
// import Foundation from 'foundation-sites';

// import { Foundation } from './theme-critical'; // currently not using



var WebFont = require('webfontloader'); // Page base

import { Foundation } from 'foundation-sites/js/foundation.core';
// import * as CoreUtils from 'foundation-sites/js/foundation.core.utils'; // not using - needed for utils ??????
// import { Box } from 'foundation-sites/js/foundation.util.box'; // Will return an object that contains the dimensions of element
import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader'; // This will execute your callback function after all the images in your jQuery collection have loaded.
import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard'; // Methods for keyboard interaction 
// import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery'; // The media query library used by Foundation has two publicly accessible functions and two properties
// import { Motion, Move } from 'foundation-sites/js/foundation.util.motion'; // Two utils
// import { Nest } from 'foundation-sites/js/foundation.util.nest'; ??????
// import { Timer } from 'foundation-sites/js/foundation.util.timer'; // Similar to setInterval, except you can pause and resume where you left off
// import { Touch } from 'foundation-sites/js/foundation.util.touch'; // Allows you to add swipe* and pseudo-drag events to elements
// import { Triggers } from 'foundation-sites/js/foundation.util.triggers'; // Provides a number of event listeners and triggers your script can hook into
// import { Abide } from 'foundation-sites/js/foundation.abide';
// import { Accordion } from 'foundation-sites/js/foundation.accordion';
import { AccordionMenu } from 'foundation-sites/js/foundation.accordionMenu'; // Page base
import { Drilldown } from 'foundation-sites/js/foundation.drilldown'; // Page base
// import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu'; // Page base
// import { Equalizer } from 'foundation-sites/js/foundation.equalizer';
// import { Interchange } from 'foundation-sites/js/foundation.interchange';
// import { Magellan } from 'foundation-sites/js/foundation.magellan';
import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas'; // Page base
// import { Orbit } from 'foundation-sites/js/foundation.orbit';
import { ResponsiveMenu } from 'foundation-sites/js/foundation.responsiveMenu'; // Page base
// import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle'; // _jwp_nav-title-bar.php
// import { Reveal } from 'foundation-sites/js/foundation.reveal';
// import { Slider } from 'foundation-sites/js/foundation.slider';
// import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll';
// import { Sticky } from 'foundation-sites/js/foundation.sticky';
import { Tabs } from 'foundation-sites/js/foundation.tabs';
// import { Toggler } from 'foundation-sites/js/foundation.toggler';
// import { Tooltip } from 'foundation-sites/js/foundation.tooltip';
// import { ResponsiveAccordionTabs } from 'foundation-sites/js/foundation.responsiveAccordionTabs';

Foundation.addToJquery($);
 
// Add Foundation Utils to Foundation global namespace for backwards
// compatibility.

// *** Not using - needed for utils ??????
// Foundation.rtl = CoreUtils.rtl;
// Foundation.GetYoDigits = CoreUtils.GetYoDigits;
// Foundation.transitionend = CoreUtils.transitionend;
// Foundation.RegExpEscape = CoreUtils.RegExpEscape;
// Foundation.onLoad = CoreUtils.onLoad;

// *** Not using - needed for utils ??????
// Foundation.Box = Box;
// Foundation.onImagesLoaded = onImagesLoaded;
// Foundation.Keyboard = Keyboard;
// Foundation.MediaQuery = MediaQuery;
// Foundation.Motion = Motion;
// Foundation.Move = Move;
// Foundation.Nest = Nest;
// Foundation.Timer = Timer;

// Touch and Triggers previously were almost purely sede effect driven,
// so no need to add it to Foundation, just init them.

// *** Not using - needed for utils ??????
// Touch.init($);
// Triggers.init($, Foundation);
// MediaQuery._init();

// Foundation.plugin(Abide, 'Abide');
// Foundation.plugin(Accordion, 'Accordion');
Foundation.plugin(AccordionMenu, 'AccordionMenu'); // Page base
Foundation.plugin(Drilldown, 'Drilldown'); // Page base
// Foundation.plugin(Dropdown, 'Dropdown');
Foundation.plugin(DropdownMenu, 'DropdownMenu'); // Page base
// Foundation.plugin(Equalizer, 'Equalizer');
// Foundation.plugin(Interchange, 'Interchange');
// Foundation.plugin(Magellan, 'Magellan');
Foundation.plugin(OffCanvas, 'OffCanvas'); // Page base
// Foundation.plugin(Orbit, 'Orbit');
Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu'); // Page base
// Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');
// Foundation.plugin(Reveal, 'Reveal');
// Foundation.plugin(Slider, 'Slider');
// Foundation.plugin(SmoothScroll, 'SmoothScroll');
// Foundation.plugin(Sticky, 'Sticky');
// Foundation.plugin(Tabs, 'Tabs');
// Foundation.plugin(Toggler, 'Toggler');
// Foundation.plugin(Tooltip, 'Tooltip');
// Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs');

export { Foundation };


// import {
//     initializer
// } from './js/foundation';
// require('waypoints/lib/noframework.waypoints.min');
// import 'slick-carousel';
// import 'slick-carousel/slick/slick.css';
// import './styles/critical.scss';
import './theme.scss';


$(document).foundation();
// initializer.key_initFoundation();
// jQuery(document).foundation();
// cannot apply update. Need to do a full reload - only accept if full relod is not needed
// if(module && module.hot){
//     module.hot.accept();
// }   
// ****************************** // 
// ****** WRAPPER  ****** // 
// ****************************** // 
// IIFE - Immediately Invoked Function Expression
(function (yourcode) {

    // The global jQuery object is passed as a parameter 
    yourcode(window.jQuery, window, document);

}(function ($, window, document) {

    // function adjustMenuWidth(arg) {
    //     let argOffset = parseInt(arg) + 70;
    //     let argOffsetPixel = argOffset + 'px';

    //     console.log('width = ' + argOffsetPixel);
    //     $('#sub-menu .parent-width').css({
    //         'max-width': argOffsetPixel,
    //     });
    // }

    // $(window).on('load', function () {
    //     const parentMenuWidth = $('#main-nav-mega').css('width');
    //     adjustMenuWidth(parentMenuWidth);

    // });

    // $(window).on('load', function () {
    //     let parentMenuWidth = $('#main-nav-mega').css('width');
    //     adjustMenuWidth(parentMenuWidth); 

    // });


    // The $ is now locally scoped  
    $(document).ready(function () {
          // *** CODE HERE *** //
          
        setTimeout(function(){
            Foundation.reInit('equalizer');
        },375);

        $('.no-fouc').removeClass('no-fouc'); 
        WebFont.load({
            custom: {
              families: ['sofia-pro', 'adobe-caslon-pro'],
            }
          });
        /**
         * File skip-link-focus-fix.js. 
         *
         * Helps with accessibility for keyboard only users.
         *
         * Learn more: https://git.io/vWdr2
         */
        (function () {
            var isIe = /(trident|msie)/i.test(navigator.userAgent);

            if (isIe && document.getElementById && window.addEventListener) {
                window.addEventListener('hashchange', function () {
                    var id = location.hash.substring(1),
                        element;

                    if (!(/^[A-z0-9_-]+$/.test(id))) {
                        return;
                    }

                    element = document.getElementById(id);

                    if (element) {
                        if (!(/^(?:a|select|input|button|textarea)$/i.test(element.tagName))) {
                            element.tabIndex = -1;
                        }

                        element.focus();
                    }
                }, false);
            }
        })();


        /*
        These functions make sure WordPress
        and Foundation play nice together.
        */
        // Remove empty P tags created by WP inside of Accordion and Orbit
        // jQuery('.accordion p:empty, .orbit p:empty').remove();

        // Adds Flex Video to YouTube and Vimeo Embeds
        // jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function () {
        //     if (jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5) {
        //         jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
        //     } else {
        //         jQuery(this).wrap("<div class='responsive-embed'/>");
        //     }
        // });

        // $('.js-drilldown-back').each(function (e) {
        //     var backTxt = $(this).parent().closest('.is-drilldown-submenu-parent').find('> a').text();
        //     console.log(backTxt);
        //     var backTxtContent = '<div class="js-drilldown-arrow-left"><a tabindex="0"><span class="drilldown-title color-primary">' + backTxt + '</span></a></div>';
        //     $(this).html(backTxtContent);
        // });

        // $('#main-nav-mega').on('show.zf.dropdownMenu', function (ev, $el) {
        //     $el.css({
        //         "display": "none"
        //     }).fadeIn({
        //         easing: 'linear',
        //         duration: 125,
        //     });
        // });

        // $('#main-nav-mega').on('hide.zf.dropdownMenu', function (ev, $el) {
        //     console.log($(this));

        //     $el.find('span').remove();
        //     console.log('true');
        //     $el.children("ul")
        //         .css('display', 'inherit').fadeOut({
        //             easing: 'linear',
        //             duration: 125,
        //         });
        // });



        
        // const api = new WooCommerceRestApi({
        //     url: "https://template.local/",
        //     consumerKey: "ck_fe8a78457f7b0475305f23902cdab8939f34298b",
        //     consumerSecret: "cs_214cc49c40965b165ea092fb0f2a49b3dfeec40a",
        //     version: "wc/v3"
        // });

        // Create a product
        // api.post("products", {
        //     name: "Product Simple Affiliate Link", // See more in https://woocommerce.github.io/woocommerce-rest-api-docs/#product-properties
        //     type: "simple",
        //     fields:{
        //         'affiliate_link': 'https://en.wikipedia.org/wiki/Truck'
        //     }
        //     })
        //     .then((response) => {


        //         console.log('first response');
        //         console.log(response);
        //                     // List products
        // api.get("products", {
        //     per_page: 20, // 20 products per page
        // })
        // .then((response) => {
        // // Successful request
        // console.log("Response Status:", response.status);
        // console.log("Response Headers:", response.headers);
        // console.log("Response Data:", response.data);
        // console.log("Total of pages:", response.headers['x-wp-totalpages']);
        // console.log("Total of items:", response.headers['x-wp-total']);
        // })
        // .catch((error) => {
        // // Invalid request, for 4xx and 5xx statuses
        // console.log("Response Status:", error.response.status);
        // console.log("Response Headers:", error.response.headers);
        // console.log("Response Data:", error.response.data);
        // })
        // .finally(() => { 
        // // Always executed.
        // });

        //     })
        //     .catch((error) => {
        //         // Invalid request, for 4xx and 5xx statuses
        //         console.log("Response Status:", error.response.status);
        //         console.log("Response Headers:", error.response.headers);
        //         console.log("Response Data:", error.response.data);
        //     })
        //     .finally(() => {
        //         // Always executed.
        //     });



        // $.ajax({
        //     url: wpApiSettings.root + 'wc/v3/products?consumer_key=ck_fe8a78457f7b0475305f23902cdab8939f34298b&consumer_secret=cs_214cc49c40965b165ea092fb0f2a49b3dfeec40a',
        //     method: 'POST',
        //     beforeSend: function (xhr) {
        //         xhr.setRequestHeader('X-WP-Nonce', wpApiSettings.nonce);
        //     },
        //     data: {
        //         'name': 'New Post Title',

        //         'affiliate_link': 'testing-123'

        //     },
        //     dataType: 'json',
        // }).done(function (response) {
        //     console.log(response);
        // });

        // $.ajax({
        //     url: wpApiSettings.root + 'wp/v2/posts',
        //     method: 'POST',
        //     beforeSend: function (xhr) {
        //         xhr.setRequestHeader('X-WP-Nonce', wpApiSettings.nonce);
        //     },
        //     data: {
        //         'title': 'New Post Title',
        //         'fields': {
        //             '_affiliate_link': 'testing-123'
        //         }
        //     }
        // }).done(function (response) {
        //     console.log(response);
        // });








        // ****************************** // 
        // ****** IN THIS POST ****** // 
        // ****************************** //


        // function htmlBuilderP(ele) {
        //     if (ele.length > 0) {
        //         let html = '<ul class="dot" data-magellan data-offset="120">';
        //         let $count = 0;
        //         ele.each(function () {
        //             $(this).attr('id', `${$count}`);
        //             $(this).attr('data-magellan-target', `${$count}`);

        //             html += `<li><a href="#${$count}">${$(this).text()}</a></li>`;
        //             $count += 1;
        //         });
        //         html += '</ul>';
        //         $('.in-this-post .post__reading-time').after(html);
        //         $(".in-this-post").foundation();
        //     } else {
        //         $('.post-template-default.single .in-this-post h3').remove();

        //     }
        // }
        // htmlBuilderP($('.single .the_content>h2'));


        // AJAX FILTER 

        // if ($('#blogfilter').length > 0) {
        //     ajaxTestFunction(false, false);
        //     var $current;
        //     // Wrap the AJAX call to `test_function` in a `function`.
        //     function ajaxTestFunction(page_num, target) {

        //         // $('#response').delay(0).fadeOut({
        //         //     easing: 'linear',
        //         //     duration: 0,
        //         // }); // insert data

        //         if (target) {
        //             $current = target;
        //             var $currentID = $($current).val();

        //             var $category = $currentID;
        //         } else {
        //             var $category = -1;
        //         }
        //         if ($category == -1) {


        //             $('.featured__post').delay(0).fadeIn({
        //                 easing: 'linear',
        //                 duration: 0,
        //             });
        //         } else {

        //             $('.featured__post').delay(0).fadeOut({
        //                 easing: 'linear',
        //                 duration: 0,
        //             });
        //         }
        //         var $action = $('#blogfilter').attr('action');
        //         var $method = $('#blogfilter').attr('method');
        //         var data = {
        //             'action': 'blogajaxfilter',
        //             'category': $category,
        //             'paged': page_num || 1
        //         };

        //         $.ajax({
        //             url: $action,
        //             data: data, // form data
        //             type: $method, // POST
        //             beforeSend: function (xhr) {
        //                 // filter.find('button').text('Processing...');
        //                 // changing the button label
        //                 // $('.blog-featured').remove();
        //             },
        //             success: function (data) {
        //                 // filter.find('button').text('Apply filter');
        //                 // changing the button label back
        //                 $('#blogresponse').css({
        //                     'display': 'none'
        //                 }).html(data).delay(0).fadeIn({
        //                     easing: 'linear',
        //                     duration: 0,
        //                 });; // insert data

        //                 if (!page_num) {

        //                     // let $recentPost = $('#blogresponse .cell:first-of-type').detach();
        //                     // $('#blogresponse').before(`<div class="grid-x grid-margin-x blog-featured"></div>`);
        //                     // $('.blog-featured').append($recentPost);
        //                     // $('.blog-featured .cell').removeClass('large-4');
        //                     // $('.blog-featured .cell').addClass('large-6');

        //                 }
        //             }
        //         });

        //         return false;
        //     }
        //     $('.blog-filter-input-label').click(function (e) {

        //         // Starts at page #1 
        //         $(".blog-filter-input-label").not($(this)).removeClass('is-active');
        //         $(this).addClass('is-active');
        //     });

        //     $('#blogfilter').change(function (e) {
        //         // Starts at page #1 
        //         ajaxTestFunction(false, e.target);
        //     });

        //     // And add a listener/callback for the pagination clicks.
        //     $('#blogresponse').on('click', '.pagination a', function (e) {
        //         e.preventDefault();
        //         var paged = /[\?&]paged=(\d+)/.test(this.href) && RegExp.$1;
        //         ajaxTestFunction(paged, $current);
        //         console.log(paged);
        //         // $('html, body').animate({ scrollTop: 0 }, 1000);
        //         if (paged == 1) {
        //             const fade = {
        //                 opacity: 1,
        //                 transition: 'opacity 0.25s'
        //             };
        //             $('.featured__post').delay(0).fadeIn({
        //                 easing: 'linear',
        //                 duration: 0,
        //             });
        //         } else {
        //             const fade = {
        //                 opacity: 0,
        //                 transition: 'opacity 0.25s'
        //             };
        //             $('.featured__post').delay(0).fadeOut({
        //                 easing: 'linear',
        //                 duration: 0,
        //             });
        //         }

        //     });

        // }

        // ****************************** // 
        // ****** SLICK ****** // 
        // ****************************** // 

        //        $('.__slider').slick({
        //            infinite: true,
        //            speed: 300,
        //            nextArrow: $('.__next'),
        //            prevArrow: $('.__prev'),
        //            mobileFirst: true,
        //            responsive: [
        //                {
        //                    breakpoint: 1,
        //                    settings: {
        //                        slidesToShow: 1,
        //                        arrows: false,
        //                        dots: true,
        //                    }
        //             },
        //                {
        //                    breakpoint: 640,
        //                    settings: {
        //                        dots: true,
        //                        arrows: false,
        //                        slidesToShow: 2
        //                    }
        //             },
        //                {
        //                    breakpoint: 1024,
        //                    settings: {
        //                        dots: false,
        //                        arrows: true,
        //                        slidesToShow: 3
        //                    }
        //             }
        //        ]
        //        });
        //        // $('.__slider').slick('slickRemove', 0);


        // ****************************** // 
        // ****** END SLICK ****** // 
        // ****************************** // 


        // ****************************** // 
        // ****** WAYPOINTS ****** // 
        // ****************************** // 
        // new Waypoint({
        //     element: document.getElementsByClassName('about')[0],
        //     handler: function () {
        //         $('.about').addClass('animated');
        //         $('.about .about-split__left-container').addClass('about-split__left-container--active');
        //         $('.about .about-split__right-container').addClass('about-split__right-container--active');
        //     },
        //     // Offsets here
        //     offset: '45%'
        // })


        //    $('#waypoint-one').css('opacity', 0);

        //    $('.about').waypoint(function (el) {
        //        $('.about').addClass('animated');
        //        $('.about .about-split__left-container').addClass('about-split__left-container--active');
        //        $('.about .about-split__right-container').addClass('about-split__right-container--active');

        //    }, {
        //        // Offsets here
        //        offset: '60%'
        //    });

        // ****************************** // 
        // ****** END WAYPOINTS ****** // 
        // ****************************** //  



        // *** END CODE *** //
        // ****************************** // 
        // ****** END WRAPPER  ****** // 
        // ****************************** //
    });
}));